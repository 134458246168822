import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import IntroSection from '../pagePartials/avail/IntroSection';
import MatchSection from '../pagePartials/avail/MatchSection';
import SecuritySection from '../pagePartials/avail/SecuritySection';
import PriceSection from '../pagePartials/avail/PriceSection';
import JoinSection from '../pagePartials/avail/JoinSection';

const Avail = props => (
  <Layout location={props.location}>
    <div className="avail-page">
      <IntroSection />
      <MatchSection />
      <SecuritySection />
      <PriceSection />
      <JoinSection />
    </div>
  </Layout>
);

Avail.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Avail;
